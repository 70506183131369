import { FC } from 'react';
import { Maybe } from 'src/types/maybe';
import { checkIfExternalLink } from 'src/atoms/fmh-link/fmh-link';
import { Icon } from 'src/components/icon-generator';
import { IconType } from 'src/components/icon-generator/dto';
import { BannerType } from 'src/organisms/fmh-banner/dto';
import Styles from './fmh-notification-banner.module.scss';
import FMHNotificationBannerProps from './dto';

export const FMHNotificationBanner: FC<FMHNotificationBannerProps> = ({
    className,
    message,
    type,
    title,
    link,
    onBannerCloseClick,
    onClickFunc,
    sticky,
    showWarningIcon,
    backgroundColor,
    titleColor,
    titleBackgroundColor,
    messageTextColor,
}: FMHNotificationBannerProps): JSX.Element => {
    const chooseBannerType = (currentType: Maybe<BannerType>): string => {
        switch (currentType) {
            case BannerType.SUCCESS:
                return Styles.bannerSuccess;
            case BannerType.WARNING:
                return Styles.bannerWarning;
            case BannerType.ERROR:
                return Styles.bannerError;
            case BannerType.ERROR_SECONDARY:
                return Styles.bannerErrorSecondary;
            case BannerType.DEFAULT:
            default:
                return Styles.bannerDefault;
        }
    };
    const isCustomizedBanner = backgroundColor && titleColor && titleBackgroundColor && messageTextColor;
    const stylingForArrowIcon = isCustomizedBanner ? Styles.customizedArrowIcon : Styles.arrowIcon;
    const getMessageBody = () => {
        return (
            <div
                className={`${onClickFunc ? Styles.clickable : ''} d-flex align-items-center`}
                style={{ color: isCustomizedBanner ? messageTextColor : '' }}
                onClick={onClickFunc ?? undefined}
            >
                {showWarningIcon && <Icon className="ff-mx-8" icon={IconType.Attention} width="24" height="24" />}
                {message}
                {onClickFunc && (
                    <Icon
                        icon={IconType.ChevronRight}
                        className={stylingForArrowIcon}
                        stroke={isCustomizedBanner ? messageTextColor : ''}
                        width="10"
                        height="10"
                    />
                )}
            </div>
        );
    };

    const getLinkMessageBody = () => {
        if (!link) return <></>;

        return checkIfExternalLink(link.uri) ? (
            <a
                style={{ color: isCustomizedBanner ? messageTextColor : '' }}
                href={link.uri}
                className={`ff-mb-0`}
                target={'_blank'}
                rel={'noreferrer'}
            >
                {showWarningIcon && <Icon className="ff-mx-8" icon={IconType.Attention} width="24" height="24" />}
                {message}
                <Icon
                    icon={IconType.ChevronRight}
                    className={stylingForArrowIcon}
                    stroke={isCustomizedBanner ? messageTextColor : ''}
                    width="10"
                    height="10"
                />
            </a>
        ) : (
            <a href={link.uri} className={`ff-mb-0`} style={{ color: isCustomizedBanner ? messageTextColor : '' }}>
                {showWarningIcon && <Icon className="ff-mx-8" icon={IconType.Attention} width="24" height="24" />}
                {message}
                <Icon
                    icon={IconType.ChevronRight}
                    className={stylingForArrowIcon}
                    stroke={isCustomizedBanner ? messageTextColor : ''}
                    width="10"
                    height="10"
                />
            </a>
        );
    };

    return (
        <div
            className={`${Styles.notificationBanner} ${sticky ? Styles.sticky : ''}
         ${className ?? ''} ${isCustomizedBanner ? '' : chooseBannerType(type)}`}
            style={{ backgroundColor: isCustomizedBanner ? backgroundColor : '' }}
        >
            <div className="container">
                <div className={`row align-items-center h-100 ${Styles.notificationInnerDiv}`}>
                    {title ? (
                        <div
                            className={`d-none d-md-block col-md-3 ff-px-4 ff-px-md-4 justify-content-center justify-content-md-start`}
                        >
                            <div
                                className={Styles.titleDiv}
                                style={{
                                    color: isCustomizedBanner ? titleColor : '',
                                    backgroundColor: isCustomizedBanner ? titleBackgroundColor : '',
                                }}
                            >
                                <p className="ff-my-0">{title}</p>
                            </div>
                        </div>
                    ) : (
                        <div className={`d-none d-md-block col-lg-3 ff-px-4 ff-px-md-4`}></div>
                    )}

                    {type === BannerType.TRANSLATION_UNAVAILABLE ? ( // to be removed later when new mulilanguages support comes
                        <div
                            className={`col-11 col-lg-8 ff-mb-0 ff-px-4 ff-px-md-8 flex-row justify-content-center justify-content-md-start ${Styles.message}`}
                        >
                            <p className={`ff-my-0`}>
                                {` ${message} `}
                                {link && (
                                    <a
                                        href={link.uri}
                                        target={checkIfExternalLink(link.uri) ? '_blank' : ''}
                                        rel={checkIfExternalLink(link.uri) ? 'noreferrer' : ''}
                                        className={`ff-mb-0 ${Styles.translationUnavailableLink}`}
                                    >
                                        {link.text}
                                        <Icon
                                            icon={IconType.ChevronRight}
                                            className={stylingForArrowIcon}
                                            stroke={isCustomizedBanner ? messageTextColor : ''}
                                            width="10"
                                            height="10"
                                        />
                                    </a>
                                )}
                            </p>
                        </div>
                    ) : (
                        <div
                            className={`col-12 col-md-8 ff-mb-0 ff-px-4 ff-px-md-8 flex-row justify-content-center justify-content-md-start ${Styles.message}`}
                        >
                            <div className={`ff-my-0`}>
                                {link ? <>{getLinkMessageBody()}</> : <>{getMessageBody()}</>}
                            </div>
                        </div>
                    )}

                    <div className={`col-1 ff-px-0 d-flex flex-row justify-content-center justify-content-md-start`}>
                        {onBannerCloseClick && (
                            <Icon icon={IconType.Cross} onClick={onBannerCloseClick} className={`${Styles.icon}`} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default FMHNotificationBanner;
